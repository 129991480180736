export const claimsConfig = {
  claimsStatusList: [
    {
      label: 'Claim Registered',
      value: 'Claim Registered',
      subList: [
        {
          label: 'Claim Registered',
          value: 'Claim Registered'
        }
      ]
    },
    {
      label: 'Waiting on Final Documents',
      value: 'Waiting on Final Documents',
      subList: [
        {
          label: 'Waiting on Final Documents',
          value: 'Waiting on Final Documents',
        },
        {
          label: 'Interim Review Due',
          value: 'Interim Review Due',
        },
        {
          label: 'Waiting on Final Correspondence',
          value: 'Waiting on Final Correspondence',
        }
      ]
    },
    {
      label: 'Claim Final Documents Lodged',
      value: 'Claim Final Documents Lodged',
      subList: [
        {
          label: 'Claim Final Documents Lodged',
          value: 'Claim Final Documents Lodged',
        }
      ]
    },
    {
      label: 'In Progress',
      value: 'In Progress',
      subList: [
        {
          label: 'Waiting Interim Payment',
          value: 'Waiting Interim Payment',
        },
        {
          label: 'Waiting Final Payment',
          value: 'Waiting Final Payment',
        },
        {
          label: 'Review Not Yet Started',
          value: 'Review Not Yet Started',
        },
        {
          label: 'Interim Payment Review',
          value: 'Interim Payment Review',
        },
        {
          label: 'Review in Progress',
          value: 'Review in Progress',
        },
        {
          label: 'Review Required of Accountant\'s Response',
          value: 'Review Required of Accountant\'s Response',
        },
        {
          label: 'Panel Review',
          value: 'Panel Review',
        },
        {
          label: 'Claim Referred to Vero',
          value: 'Claim Referred to Vero',
        }
      ]
    },
    {
      label: 'Waiting on Response from Accountant',
      value: 'Waiting on Response from Accountant',
      subList: [
        {
          label: 'Waiting on Invoice',
          value: 'Waiting on Invoice'
        },
        {
          label: 'Waiting on WIP',
          value: 'Waiting on WIP'
        },
        {
          label: 'Waiting on status update',
          value: 'Waiting on status update'
        },
        {
          label: 'First Followup Sent',
          value: 'First Followup Sent'
        },
        {
          label: 'Second Followup Sent',
          value: 'Second Followup Sent'
        },
        {
          label: 'Third Followup Sent',
          value: 'Third Followup Sent'
        },
        {
          label: 'Fourth Followup Sent',
          value: 'Fourth Followup Sent'
        },
        {
          label: 'Fifth Followup Sent',
          value: 'Fifth Followup Sent'
        },
        {
          label: '"One Last" Response',
          value: 'One Last Response'
        },
        {
          label: 'Registration Query',
          value: 'Registration Query'
        },
      ]
    },
    {
      label: 'Claim Finalised',
      value: 'Claim Finalised',
      subList: [
        {
          label: 'Claim Paid',
          value: 'Claim Paid'
        },
        {
          label: 'Claim Withdrawn',
          value: 'Claim Withdrawn'
        },
        {
          label: 'Claim Declined',
          value: 'Claim Declined'
        }
      ]
    }
  ],
  claimLodgedToList: [
    { value: 'Vero Direct', label: 'Vero Direct' },
    { value: 'AIU Direct', label: 'AIU Direct' },
  ]
};
